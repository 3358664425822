<script setup lang="ts">
import { h, onMounted, onUnmounted, ref, watch } from "vue"
import { DownloadOutlined } from "@ant-design/icons-vue"
import { storeToRefs } from "pinia"
import { push } from "notivue"
import { useToggle } from "@vueuse/core"

import { useAdoptable } from "@/package/ui-kit"
import { ReportUsnForm, useReportsStore } from "@/modules/reports"

import { useColumns } from "./use-columns"

const isAdoptable = useAdoptable()
const { columns } = useColumns()
const store = useReportsStore()
const { downloadReportError } = storeToRefs(store)

const loadingState = ref<string | null>(null)

const tableWrapper = ref<HTMLElement | null>(null)

const [show, toggleShow] = useToggle(false)

const stickyConfig = {
  offsetHeader: 0,
  offsetScroll: 0,
  getContainer: () => tableWrapper.value
}

watch(downloadReportError, (err) => {
  if (err?.message) {
    push.error({ message: err.message })
  }
})

onMounted(store.getFormatListing)
onUnmounted(store.$reset)

const downloadReport = (type: string) => {
  if (type === "usn") {
    toggleShow(true)
    return
  }
  loadingState.value = type

  store.downloadReport(type).finally(() => {
    loadingState.value = null
  })
}
</script>

<template>
  <div ref="tableWrapper" :style="{ overflow: 'auto', height: '100%' }">
    <ATable
      :size="isAdoptable ? 'middle' : 'large'"
      :sticky="stickyConfig"
      class="custom-table"
      :loading="false"
      :columns="columns"
      :data-source="store.formatListing"
      :show-header="!isAdoptable"
      :pagination="false"
      row-key="id"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'formatName'">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <AText>{{ record.name }}</AText>
            <AButton
              :icon="h(DownloadOutlined)"
              type="primary"
              :loading="loadingState === record.type"
              :disabled="loadingState && loadingState !== record.type"
              @click="downloadReport(record.type)"
            >
              {{ !isAdoptable ? "Скачать" : "" }}
            </AButton>
          </div>
        </template>
      </template>
    </ATable>
  </div>
  <ReportUsnForm :open="show" @close="toggleShow(false)" />
</template>

<style scoped>
.custom-table {
  max-width: calc(100% - 2px);
}

.custom-table:deep(.ant-table-wrapper),
.custom-table:deep(.ant-spin-container),
.custom-table:deep(.ant-table-container),
.custom-table:deep(.ant-spin-nested-loading),
.custom-table:deep(.ant-table-content table) {
  height: 100%;
}

.custom-table:deep(.ant-spin-container) {
  display: flex;
  flex-direction: column;
}

.custom-table:deep(.ant-table-container)::after,
.custom-table:deep(.ant-table-container)::before {
  content: none !important;
}
</style>
