<script setup lang="ts">
import dayjs, { type Dayjs } from "dayjs"
import { useToggle } from "@vueuse/core"
import { h, nextTick, onMounted, onUnmounted, ref, watch } from "vue"
import { FilterOutlined } from "@ant-design/icons-vue"

import { LegalEntitySelect } from "@/modules/legal-entity"
import { ProjectSelect } from "@/modules/project"
import { useAdoptable, useTableResolution } from "@/package/ui-kit"
import { injectProfileStatus } from "@/modules/profile"
import { getFilterDatesPresets } from "@/package/util"
import { FiltersMobileForm, useReportsStore, type ReportsFiltersData } from "@/modules/reports"

const [isDatepickerOpened, toggleDatepickerOpened] = useToggle(false)
const store = useReportsStore()
const backupDateState = ref<Array<Dayjs>>([])
const currentDates = ref<Array<Dayjs>>([])
const [showFilterPanel, toggleShowFilterPanel] = useToggle(false)
const isAdoptable = useAdoptable()
const isTableResolution = useTableResolution()
const [isDateChanging, toggleIsDateChanging] = useToggle<boolean>(false)
const profileStatus = injectProfileStatus()

const currentDate = dayjs()
const datePresets = getFilterDatesPresets(currentDate)

const closeDatePicker = async () => {
  backupDateState.value = []
  toggleDatepickerOpened(false)
  await nextTick(() => {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
  })
}

const applyDatePicker = async () => {
  store.reportsFilters.dateFilter = currentDates.value
  await closeDatePicker()
}

const cancelDateSelect = () => {
  store.reportsFilters.dateFilter = backupDateState.value
  closeDatePicker()
}

const focusRangePicker = () => {
  if (!isDatepickerOpened.value) {
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
  }

  if (!isDatepickerOpened.value) {
    backupDateState.value = store.reportsFilters.dateFilter
    toggleDatepickerOpened(true)
  }
}

const blurRangePicker = () => {
  if (!isDateChanging.value) {
    toggleDatepickerOpened(false)
  } else {
    toggleIsDateChanging(false)
  }
}

const handleCalendarChange = (
  dates: Array<Dayjs>,
  dateString: Array<string>,
  info: { range: string }
) => {
  if (!dates) {
    toggleDatepickerOpened(false)
    store.reportsFilters.dateFilter = []
    ;(document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.blur()
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.blur()
    return
  }

  currentDates.value = dates
  if (info.range === "start" && dates[0]) {
    toggleIsDateChanging(true)
    ;(document.querySelector(".ant-picker")?.children[2]?.children[0] as HTMLInputElement)?.focus()
  }

  if (info.range === "end" && dates[1]) {
    toggleIsDateChanging(true)
    nextTick(() =>
      (document.querySelector(".ant-picker")?.children[0]?.children[0] as HTMLInputElement)?.focus()
    )
  }
}

onMounted(store.getReportFilters)

watch(
  store.reportsFilters,
  () => {
    if (store.isFiltersLoaded && !profileStatus.value.asAdmin) store.saveReportFilters()
  },
  { deep: true, immediate: false }
)

onUnmounted(() => {
  backupDateState.value = []
})

const updateStateFromMobileForm = (newState: Partial<ReportsFiltersData>) => {
  store.reportsFilters.projectsFilter = newState.projectsFilter ?? []
  store.reportsFilters.legalEntityFilter = newState.legalEntityFilter ?? null
  store.reportsFilters.dateFilter = newState.dateFilter ?? []
  toggleShowFilterPanel(false)
}

const cancelMobileForm = () => {
  toggleShowFilterPanel(false)
}
</script>

<template>
  <ARow
    :style="{
      marginBottom: '24px',
      marginTop: '32px',
      flexDirection: isTableResolution ? 'column' : 'row'
    }"
    :gutter="24"
  >
    <ACol
      v-if="!isAdoptable"
      :flex="isTableResolution ? null : '0 1 276px'"
      style="padding-bottom: 24px; box-sizing: content-box; max-width: 276px"
    >
      <ARangePicker
        :value="store.reportsFilters.dateFilter"
        :format="['DD-MM-YYYY', 'DD-MM-YYYY']"
        :open="isDatepickerOpened"
        :placeholder="['Начало', 'Конец']"
        :presets="datePresets"
        popup-class-name="reports-filters-date-picker"
        size="large"
        @focus.prevent="focusRangePicker"
        @calendarChange="handleCalendarChange"
        @blur.prevent="blurRangePicker"
      >
        <template #renderExtraFooter>
          <AFlex gap="8" justify="end" :style="{ padding: '8px' }">
            <AButton size="small" type="default" @click="cancelDateSelect">Отмена</AButton>
            <AButton size="small" type="primary" @click="applyDatePicker"> Применить </AButton>
          </AFlex>
        </template>
      </ARangePicker>
    </ACol>

    <ACol v-if="!isAdoptable" style="padding-bottom: 24px">
      <LegalEntitySelect
        v-model.null-on-empty="store.reportsFilters.legalEntityFilter"
        style="width: 276px"
        hide-link
        placeholder="Выберите юр.лицо"
      />
    </ACol>

    <ACol
      v-if="!isAdoptable"
      :flex="isTableResolution ? null : '0 1 276px'"
      style="padding-bottom: 24px"
    >
      <ProjectSelect
        v-if="store.reportsFilters.projectsFilter"
        v-model.null-on-empty="store.reportsFilters.projectsFilter"
        mode="multiple"
        style="width: 276px"
        :max-tag-count="2"
        hide-link
        placeholder="Выберите проекты"
      />
    </ACol>

    <ACol v-if="isAdoptable" style="display: flex; justify-content: flex-end; padding-right: 22px">
      <AButton
        size="large"
        type="primary"
        :icon="h(FilterOutlined)"
        @click="() => toggleShowFilterPanel(true)"
      />

      <FiltersMobileForm
        v-if="showFilterPanel"
        :show-filter-panel="showFilterPanel"
        @submit="updateStateFromMobileForm"
        @cancel="cancelMobileForm"
      />
    </ACol>
  </ARow>
</template>

<style>
.reports-filters-date-picker .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.reports-filters-date-picker
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.reports-filters-date-picker .ant-picker-panels > *:last-child {
  display: none !important;
}
</style>
