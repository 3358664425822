<script setup lang="ts">
import { TaxRate, TaxRateDict } from "@/modules/reports"

const options = (
  Object.keys(TaxRate).filter((k) => typeof TaxRate[k as any] === "number") as Array<
    keyof typeof TaxRate
  >
).map((key) => ({
  value: TaxRate[key],
  label: TaxRateDict[TaxRate[key]]
}))
</script>

<template>
  <ASelect :options="options" placeholder="Выберите код признака применения налоговой ставки" />
</template>
